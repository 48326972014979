//
// card.scss
//

.card {
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;

    .header-title {
        margin-bottom: 0.5rem;
        font-size: 0.95rem;
        margin-top: 0;
    }
    
    .card-drop {
        font-size: 20px;
        color: inherit;
    }

    .card-widgets {
        float: right;
        height: 16px;

        >a {
            color: inherit;
            font-size: 18px;
            display: inline-block;
            line-height: 1;
            min-width: 20px;

            &.collapsed {
                i {
                    &:before {
                        content: "\EA13";
                    }
                }
            }
        }
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;

    .header-title {
        margin-bottom: 0;
    }
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($light, .8);
    cursor: progress;

    .card-portlets-loader {
        background-color: var(--#{$prefix}gray-900);
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -($spacer * 0.5);
        margin-top: -($spacer * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card height
.card-h-100 {
    height: calc(100% - #{$spacer});
}