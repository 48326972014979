// 
// light-mode.scss
// 


// ================================ //
// ======== Custom Variable ======= //
// ================================ //

:root {
    --#{$prefix}logo-lg-height:           26px;
    --#{$prefix}logo-sm-height:           28px;

    --#{$prefix}leftbar-width:            240px;
    --#{$prefix}leftbar-width-md:         160px;
    --#{$prefix}leftbar-width-sm:         70px;
    --#{$prefix}leftbar-condensed-height: 1500px;

    --#{$prefix}topbar-height:             70px;

    --#{$prefix}menu-item-icon-size:       1.1rem;
    --#{$prefix}menu-item-icon-width:      30px;
    --#{$prefix}menu-item-font-size:       0.875rem;
    --#{$prefix}menu-item-padding-x:       12px;
    --#{$prefix}menu-item-padding-y:       8px;

    // Footer Height
    --#{$prefix}footer-height:             50px;

    // Card Border Width
    --#{$prefix}theme-card-border-width:   0px;
}

// ================================ //
// ========== Main Menu =========== //
// ================================ //

// Background Light left-sidebar
html[data-menu-color="light"] {
    --#{$prefix}menu-bg:                             #ffffff;
    --#{$prefix}menu-item-color:                       #{$gray-700};
    --#{$prefix}menu-item-hover-color:                 #{$primary};
    --#{$prefix}menu-item-active-color:                #{$primary};
    --#{$prefix}menu-condensed-link-bg:                #313a46;
    --#{$prefix}help-box-bg:                           #{$primary};
}

// Dark Left Sidebar
html[data-menu-color="dark"] {
    --#{$prefix}menu-bg:                                 #{$gray-900};
    --#{$prefix}menu-item-color:                       #8391a2;
    --#{$prefix}menu-item-hover-color:                 #bccee4;
    --#{$prefix}menu-item-active-color:                #ffffff;
    --#{$prefix}menu-condensed-link-bg:                #313a46;
    --#{$prefix}help-box-bg:                             #{rgba($white, 0.07)};
}

// Brand Menu
html[data-menu-color="brand"] {
    --#{$prefix}menu-bg:                                   #{linear-gradient(135deg, #6379c3 0%, #4254ba 60%)};
    --#{$prefix}menu-item-color:                           #cedce4;
    --#{$prefix}menu-item-hover-color:                     #ffffff;
    --#{$prefix}menu-item-active-color:                    #ffffff;
    --#{$prefix}menu-condensed-link-bg:                    #313a46;
    --#{$prefix}help-box-bg:                               #{rgba($white, 0.07)};
}

// Dark Mode Left Sidebar
html[data-bs-theme="dark"][data-menu-color="light"],
html[data-bs-theme="dark"][data-menu-color="dark"] {
    --#{$prefix}menu-bg:                               #313a46;
    --#{$prefix}menu-item-color:                       #8391a2;
    --#{$prefix}menu-item-hover-color:                 #bccee4;
    --#{$prefix}menu-item-active-color:                #ffffff;
    --#{$prefix}menu-condensed-link-bg:                #313a46;
    --#{$prefix}help-box-bg:                             #{rgba($white, 0.07)};
}


// ================================ //
// ========== Topbar ============== //
// ================================ //

// Light Topbar
html[data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                         #ffffff;
    --#{$prefix}topbar-item-color:                 #{$gray-600};
    --#{$prefix}topbar-item-hover-color:           #{$primary};
    --#{$prefix}topbar-search-bg:                  #{tint-color($gray-200,10%)};
    --#{$prefix}topbar-user-bg:                    #{lighten($gray-100, 1%)};
    --#{$prefix}topbar-user-border:                #eef2f7;
}


// Dark Topbar
html[data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                  #313a46;
    --#{$prefix}topbar-item-color:          #8391a2;
    --#{$prefix}topbar-item-hover-color:    #bccee4;
    --#{$prefix}topbar-search-bg:           #464f5b;
    --#{$prefix}topbar-user-bg:             #3c4655;
    --#{$prefix}topbar-user-border:         #414d5d;
}

// Brand Topbar
html[data-topbar-color="brand"] {
    --#{$prefix}topbar-bg:                         #{$primary};
    --#{$prefix}topbar-item-color:                 rgba(255, 255, 255, 0.7);
    --#{$prefix}topbar-item-hover-color:           #ffffff;
    --#{$prefix}topbar-search-bg:                  rgba(255, 255, 255, 0.1);
    --#{$prefix}topbar-user-bg:                    rgba(255, 255, 255, 0.1);
    --#{$prefix}topbar-user-border:                rgba(255, 255, 255, 0.15);
}

// Dark Mode Topbar
html[data-bs-theme="dark"][data-topbar-color="light"],
html[data-bs-theme="dark"][data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                  #2f3742;
    --#{$prefix}topbar-item-color:          #8391a2;
    --#{$prefix}topbar-item-hover-color:    #bccee4;
    --#{$prefix}topbar-search-bg:           #363f4a;
    --#{$prefix}topbar-user-bg:             #363f4a;
    --#{$prefix}topbar-user-border:         #414d5d;
}